import * as React from "react";
import { Wrapper, ListWrapper, ButtonWrapper,Heigh } from "./Expositions.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";
import Button from "./../../../components/Button";
import DondiWhite from "./../../../res/Dondi/exposition/dondi-tile.png";
import NielsShoeMeulman from "../../../res/shoe/expo/shoe-tile.png"
import Aone from "./../../../res/Aone/exposition/aone-tile.png"
import Daze from "./../../../res/Daze/exposition/daze-tile.png"
import KoolKoor from "./../../../res/KoolKoor/expo/koolkoor-tile.png"
import EventCardExpo from "../../../components/EventCardExpo";
import CoolTabs from 'react-cool-tabs';
import { useState } from "react";
import NF1 from "../../../res/Nos fantomes/fantomes-tile.png"
import PhantomV2 from "../../../res/Nos fantomes V2/fantomes-v2-tile.png"
import StudioVsVd from "../../../res/studiovsvandalism/studio-vs-vandalism-tile.png"
import MrsMoment from "../../../res/landing/tile-expo-marseille.png"
import PrsMoment from "../../../res/landing/tile-expo-paris.jpeg"
import NF2 from "../../../res/landing/winter-tile.png"
import NF3 from "../../../res/New York Street Style/cover.png"
import VIRTUAL from "../../../res/landing/virtual-visit.png"
import GDTILE from "../../../res/graffitiDynamics/tile.png"
import EPODETILE from "../../../res/epode/epode.jpg"
import DTILE from "../../../res/Dauphine/tile.jpg"
import RTILE from "../../../res/Reminiscence/tile.png"
import CTILE from "../../../res/crash/tile.jpg"
import CTILE2 from "../../../res/crash/tile-2.png"
import PTILE from "../../../res/summer/slide-piercing.jpg"


const actualExhibitions = [

  {
    date: "June 6th - August 31st 2024",
    title: "HIGH SUMMER VIBES",
    link: "",
    artist: "John CRASH Matos (NYC, 1961)",
    place: "GHOST GALERIE x CHÂTEAU DE LA GAUDE - Aix-en-Provence",
    description: "Hors les Murs exhibition in Aix-en-Provence. John CRASH Matos. GHOST GALERIE x CHÂTEAU DE LA GAUDE. HIGH SUMMER VIBES. Works from the 80s to the present day. Free admission every day throughout the summer. Signed & numbered boxed set / 25 copies including the 4 prints numbered from 1 to 25. 4 prints published for the occasion / 50 copies each. The 4 prints together form the original work hung for the exhibition.",
    presentation_photo: CTILE2,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "Summer 2024",
    title: "ENTWINED VISIONS",
    link: "",
    artist: "Futura (2000), Richard Hambleton, Invader",
    place: "Marseille",
    description: "In the captivating world of contemporary art, the union between imagination and reality takes shape through the unique creations of artists, inviting viewers to plunge into a universe imbued with fascination. At the heart of the summer exhibition “Entwined Visions” is the dazzling talent of three contemporary artists: Richard Hambleton, Futura (2000) and Invader. Their works reveal not only their singular perception of the world, but also the way in which they interweave to form a dynamic and teeming artistic landscape.",
    presentation_photo: PTILE,
    photos_paths: ["path", "path", "path", "path"],
  },
];

const passedExhibitions = [
  {
    date: "March 21st - April 21st 2024",
    title: "ECHOES OF THE PAST",
    link: "../expositions/crash-expo",
    artist: "TIMELESS CREATIVITY & EXPLORATION FROM 1980 TO 1990",
    place: "Paris",
    description: "This exhibition presents a rare opportunity to delve into the fascinating creative universe of one of New York's graffiti pioneers in the 80s and 90s, who became a key figure in the Post-graffiti movement.    This retrospective offers a privileged insight into CRASH's artistic development through a selection of emblematic works from this period. \"Each piece, imbued with passion and perseverance, transcends the limits of time to create a timeless dialogue with the viewer. His early works emerge as masterpieces, boldly transcending the codes of contemporary art. They present themselves not only as aesthetic creations, but also as fearless testimonies to a resolutely provocative artistic expression. In each aerosol shade lies an untold story, an emotion to be revealed. At the heart of the tumult of pigments, harmony takes shape\".    The beginnings of his work are rooted in the exuberant effervescence of the 70s, amid the unbridled hustle and bustle of an underground New York bubbling with vitality and creative genius. \"Echoes of the Past\" represents a creative dialogue. Each work reflects an experience, a memory. His singular style, a harmonious fusion of lettering and comics, becomes a colorful melody dancing to the rhythm of his imagination and inspiration.”",
    presentation_photo: CTILE,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "October 13th - November 18th 2023",
    title: "REMINISCENCE",
    link: "../../en-projects/expositions/reminiscence",
    artist: "",
    place: "Paris",
    description:
    "Ghost Galerie is pleased to present the second exhibition in its program dedicated to the emerging art scene: Ghost Project. Reminiscence, running from October 13th to November 18th, will present the work and universe of 5 artists: Hakim Sahiri, Clémence Gbonon, Djabril Boukhenaïssi, Félix Taburet & Hannah Becquante",
    presentation_photo: RTILE,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "June 15th - October 7th 2023",
    title: "GRAFFITI DYNAMICS",
    link: "../expositions/graffiti-dynamics",
    artist: "GROUP SHOW",
    place: "Paris & Marseillle",
    description:
    "GHOST galerie is pleased to present its new exhibition: Graffiti Dynamics, from June 15 to October 7th, 2023, between Paris and Marseille. An immersion in the New York art scene of the 80s and nowadays !",
    presentation_photo: GDTILE,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "September 28th - October 1st 2023",
    title: "INVISIBLES",
    link: "../expositions",
    artist: "Galerie Dauphine",
    place: "Paris",
    description:
    "Galerie Dauphine is an association dedicated to the promotion of contemporary art. It is represented by a group of art market and asset management professionals, with the support of Dauphine-PSL executive education program and Christie's auction house. The Invisibles exhibition will run from September 28 to October 1, 2023 at Ghost Galerie, 62 rue du Faubourg Saint-Honoré, Paris. It will feature a group show by 8 artists. Profits from the sale will be donated by Galerie Dauphine to the Art Exprim association, whose commitment is to make contemporary art accessible to all, in order to create together a more united and creative society. The vernissage will take place on September 28 from 6 pm to 9 pm",
    presentation_photo: DTILE,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "April 7th - June 3rd 2023",
    title: "EPODE",
    link: "../../en-projects/expositions/epode",
    artist: "ANA MONSÓ & MATHIAS BENSIMON",
    place: "Paris",
    description:
    "Ghost Galerie is pleased to present its new exhibition: Epode, from April 7 to May 13, 2023. A new chapter in its history, this event is part of a new exhibition program - Ghost Project - highlighting the emerging contemporary art scene. Opening on April 6 from 5pm in the presence of the artists.",
    // "Ghost Galerie a le plaisir de vous présenter sa nouvelle exposition : Épode, du 7 avril au 13 Mai 2023 > Prolongation jusqu'au 3 juin!. \nNouveau chapitre de son histoire, cet événement s’inscrit dans un nouveau programme d’exposition - Ghost Project - mettant en lumière la scène artistique émergente contemporaine.",
    // "La Ghost Galerie est heureuse de vous présenter son nouvel accrochage : A MOVEMENT IN MOTION. Pour ce nouvel accrochage hivernal, les oeuvres bougeront entre Paris et Marseille et inversement, Elles apparaîtrons ou disparaîtront sans prévenir ! Une double exposition qui nous permet de vous proposer entre Paris et Marseille plus d'une cinquantaine d'oeuvres des années 80 à nos jours de plusieurs artistes, Dévoilant ainsi au regard du visiteur novice ou averti, le fondement même de ce mouvement artistique : le mouvement en mouvement.",
    presentation_photo: EPODETILE,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "WINTER EXHIBITIONS",
    title: "A MOVEMENT IN MOTION",
    link: "",
    artist: "",
    place: "Marseille & Paris",
    description:
    "Ghost Galerie is pleased to present its new exhibition: A MOVEMENT IN MOTION. For this new winter exhibition, the works will move between Paris and Marseille and vice versa, they will appear or disappear without warning! A double exhibition that allows us to offer you between Paris and Marseille more than fifty works from the 80's to nowadays by several artists, thus unveiling to the eyes of the novice or informed visitor, the very foundation of this artistic movement: movement in movement.",
    presentation_photo: NF2,
    photos_paths: ["path", "path", "path", "path"],
  },
      {
      date: "360°",
      title: "VIRTUAL VISIT (A MOVEMENT IN MOTION)",
      link: "../visite-virtuelle",
      artist: "",
      place: "Paris",
      description:
        "A 360° discovery of our Parisian gallery! An immersion, the time of this visit, in this culture of the 80s and in this contemporary creation. \n© 2023. All rights reserved",
      presentation_photo: VIRTUAL,
      photos_paths: ["path", "path", "path", "path"],
    },
  {
    date: "2022",
    title: "THEN & NOW",
    link: "then-and-now",
    artist: "New York Street Style V2",
    place: "Paris",
    description:
    "The Ghost Galerie is pleased to present its new exhibition: Then and Now, New York Street Style. A \"different continuity\" of its summer exhibition: New York Street Style, creation of the 80s. An exhibition that allows us to see, on the 3 floors of the gallery, about forty works from the 80's to nowadays, from about twenty artists. An artistic discovery mixing paintings, works on paper and sculptures, gathered around 3 styles: abstract art, figurative and lettering. Not to mention photography and installations!",
    presentation_photo: PrsMoment,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "2022",
    title: "THE NAME IS THE FAME OF THE GAME",
    link: "the-fame-is-the-name-of-the-game",
    artist: "",
    place: "Marseille",
    description:
    "GHOST GALERIE Marseille unveils a new series of exhibitions. A selection of works by New York artists produced in the 80s and 90s. A moving display that allows visitors to live an irreplaceable experience, a sensitive and personal encounter with this contemporary creation, straight out of the imagination of pre-teens & teens. The exhibition the name is the fame of the game allows us to dive back into the foundations of New York graffiti and its mental and artistic constructions once reinvented in the studio.",
    presentation_photo: MrsMoment,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "July - September 2022",
    title: "NEW YORK STREET STYLE",
    link: "ny-street-style",
    artist: "",
    place: "Paris",
    description:
    "In the 70’s New York, while artists were taking over the city, they formed around a new culture that was their own, with its own codes, language, style, and 'attitude’. From those mixed codes of ‘ghetto children’ of The Bronx or Brooklyn, they mark the beginning of hip-hop culture, urban movement, clandestine and protesting which include DJing, rap, beatbox, breakdance and graffiti. A carefully studied carelessness for language and style opened a new way to design: casual chic. Hip-hop has played a leading role, major in the history and democratization of sneakers, and since 1985, the Jordan Nike has been their star. ",
    presentation_photo: NF3,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "February - May 2022",
    title: "STUDIO VS VANDALISM",
    link: "studio-vs-vandalism",
    artist: "",
    place: "Paris",
    description:
    "What happens when artists move from the street to the studio ? Is this work in the studio a continuation of their vandal interventions in the urban space ? How and why were they able to create a real artistic movement born in the street; a movement that from the 80's onwards is found in galleries and in museum institutions ? So many questions that leave one wondering but that can find some answers thanks to the exhibition Studio VS Vandalism, a display of works by about fifteen artists on the 3 floors of the gallery. Works from the 80's to today, with multiple supports and expressions.",
    presentation_photo: StudioVsVd,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "October - January 2022",
    title: "NOS FANTÔMES V2",
    link: "nos-fantomes-v2",
    artist: "",
    place: "Paris",
    description:
    "On the occasion of FIAC 2021, GHOST GALERIE, specialist of Post-graffiti, presents a V2 of its inaugural exhibition ''Our Ghosts''. This event will bring together historical and more recent works by some twenty artists through forty works. Ghosts are everywhere or maybe nowhere. They reflect our fears, our fantasies or perhaps even our imagination. They appear or disappear, just like the works exhibited on the 3 levels of the gallery. Were they present during the first hanging? Not sure. Do they always converse with their same neighbors ? Not obvious. Were the creators of these neighbors already exhibited? To be discovered.",
    presentation_photo: PhantomV2,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "July - October 2021",
    title: "NOS FANTÔMES",
    link: "nos-fantomes",
    artist: "",
    place: "Paris",
    description:
    "Thus are invoked the ghosts that inhabit Ghost Galerie’s primary DNA, the spectres of its passion for the initiators of American Graffiti. The first works on canvases by the major figures of New York Graffiti are the rare historical and physical evidence of the premises of a liberated and conquering expression of popular culture, which grew into a global phenomenon and kept its vital strength to this day. These Post-graffiti works, created by the artists that were most present in the streets and in the subways at the beginning of the 1980’s but were also invited to the most prominent New York galleries at the time (Sidney Janis, Annina Nosei and Tony Shafrazi), reveal how their individual practices evolved in the private atmosphere of the studio.",
    presentation_photo: NF1,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "July - August 2021",
    title: "ROOKS & ROBOTS",
    link: "kool-koor",
    artist: "Kool Koor",
    place: "Marseille",
    description:
    "Marseille, June 28, 2021. The Château de Forbin and the GHOST GALERIE Marseille, two contemporary art venues in Marseille dedicated to graffiti and Post-graffiti of the 80s, will host from July 4 to August 4, 2021 the first double exhibition of New York artist Kool Koor. This event is a first for the Brussels-based artist. Named \"Rooks & Robots\" in homage to his artist's name, this double exhibition to be discovered soon is a return to the origins of the artist as well as to the universe of his very first works, a very galactic era on the theme of Rooks & Robots.",
    presentation_photo: KoolKoor,
    photos_paths: ["path", "path", "path", "path"],
  },
  {
    date: "November - December 2020",
    title: "40 ANS DE CRÉATION",
    link: "daze",
    artist: "Daze",
    place: "Marseille",
    description:
    "Marseille, November 5, 2020. The GHOST gallery and the Château de Forbin, two contemporary art venues in Marseille - dedicated to graffiti and Post-graffiti of the 80s - will host from November 5 to December 5, 2020 the first \"double\" exhibition of the New York artist Chris DAZE Ellis retracing his 40 years of artistic creation. A rich retrospective of more than 60 works that relates the evolution of his work over his entire career.",
    presentation_photo: Daze,
    photos_paths: ["path", "path", "path", "path"],
  },
  // {
  //   date: "2021",
  //   title: "Group show 4 - Accrochage en mouvement",
  //   link: "",
  //   place: "Marseille",
  //   description:"Un nouvel accrochage en mouvement. Des oeuvres figuratives, abstraites ou tout en lettrages. Une rencontre avec cette création artistique, une fois de plus quand cela est possible!",
  //   presentation_photo: DondiWhite,
  //   photos_paths: ["path", "path", "path", "path"],
  // },
  {
    date: "June - July 2019",
    title: "RETROSPECTIVE 80's & 90's",
    link: "aone",
    artist: "Aone",
    place: "Marseille",
    description:
    "Admired, respected and considered as a major artist of the movement, AONE has contributed - thanks to his wild, philosophical, mystical & spiritual creations - to the influence of this culture, well beyond the borders of the South Bronx. Born in the ghetto, his apprenticeship was done on the street and in the subway. It is in self-taught that he will conquer the art world. At the end of the 70's, graffiti was then an impetuous geyser that only asked to explode from this underground scene, in which the majority of gallery owners, curators and journalists, seemed to want to confine it. To the rhythm of hip hop and breakdance, there was then a whole generation of artists, spirited teenagers, who wanted to show what their art, not could be, but was. AONE was one of them, spinning his bombs in an energetic and contagious dance.",
    presentation_photo: Aone,
    photos_paths: ["path", "path", "path", "path"],
  },
  // {
  //   date: "2020",
  //   title: "Group show 3 - Accrochage en mouvement",
  //   link: "",
  //   place: "Marseille",
  //   description:"Cette nouvelle année d’accrochages à dû s’adapter à la période Mays a tout de même permis aux visiteurs, quand cela était possible bien évidemment, de pouvoir continuer à découvrir de nouvelles oeuvres de Post graffiti, de la scène de l’East Village et de la scène contemporaine.",
  //   presentation_photo: DondiWhite,
  //   photos_paths: ["path", "path", "path", "path"],
  // },
  {
    date: "April - May 2019",
    title: "UNSTALLATION",
    link: "niels-shoe-meulman",
    artist: "Niels Shoe Meulman",
    place: "Marseille",
    description:
    "On April 28, 2019, Ghost will host the opening of Unstallation, a solo exhibition by graffiti/street artist Niels Meulman, better known as Shoe (Amsterdam, 1967). In the 1980s, Shoe was personally introduced to the art movement by New York's finest, such as Dondi White, Rammellzee and Keith Haring. Niels Shoe Meulman is an internationally renowned artist, graphic designer and art director from Amsterdam. Meulman began tagging Shoe in 1979 and became a graffiti legend at the age of 18. Known as \"Shoe\" Meulman is a visual artist, known for his gestural paintings that reveal vivid traces of graffiti and calligraphy. He revolutionized the art of writing by launching the Calligraffiti movement, asserting that a word is a picture and writing is a painting.",
    presentation_photo: NielsShoeMeulman,
    photos_paths: ["path", "path", "path", "path"],
  },
  // {
  //   date: "2019",
  //   title: "Group show 2 - Accrochage en mouvement",
  //   link: "",
  //   place: "Marseille",
  //   description:"Pour cette saison 2019, le positionnement de GHOST GALERIE Marseille nous permettra de continuer à faire découvrir à nos visiteurs les artistes de la scène New-Yorkaise des années 80-90. Le post graffiti et le quartier de l’East Village à Marseille!  mis en perspective avec la création contemporaine. Ce n’est donc pas une expo tous les mois Mays une expo en continue au fil des saisons, le concept de GHOST!",
  //   presentation_photo: DondiWhite,
  //   photos_paths: ["path", "path", "path", "path"],
  // },
  {
    date: "April - May 2018",
    title: "DONDISM",
    link: "dondi-white",
    artist: "Dondi White",
    place: "Marseille",
    description:
    "DONDI WHITE - also known as DONDI - is one of the founding \"fathers\" of the New York graffiti of the 70s and 80s; The King of New York figurative graffiti and lettering; An undisputed king of mythical \"wholes cars\" - such as the famous Children of the Grave created in 1980. His graphic talent allowed him to evolve his aestheticism - going from subway trains to canvas to become one of the major artists of the 20th century.",
    presentation_photo: DondiWhite,
    photos_paths: ["path", "path", "path", "path"],
  },
  // {
  //   date: "2018",
  //   title: "Group show 1 - Accrochage en mouvement",
  //   link: "",
  //   place: "Marseille",
  //   description:"Un parcours qui emmène le visiteur au contact d’oeuvres qui célèbrent l’art américain  des années 80-90 avec des oeuvres d'atelier, iconiques, emblématiques ou singulières réalisées par les maîtres de l’aérosol, pionniers du graffiti new-yorkais Old School et des oeuvres réalisées par les représentants de la scène artistique de l’East Village confrontés à des artistes de la scène actuelle.Un accrochage qui évoluera tout au long de l’année composé d’écriture, de figuratif et d’abstrait, de poésie et d’émotions, de futur et de liberté.",
  //   presentation_photo: DondiWhite,
  //   photos_paths: ["path", "path", "path", "path"],
  // },
];

var deviceWidth = 0;
if (typeof window !== 'undefined') {
  deviceWidth = window.innerWidth;
}

// TODO 430 Plus grosse width de telephone
var test = deviceWidth < 430 ? 320 : 375;

const Expositions = () => {
  const [trueHeight, setTrueHeight] = useState(actualExhibitions.length * test)
  const [left, setLeft] = useState(true)
  const [right, setRight] = useState(false)
  // setTrueHeight([actualExhibitions.length * test]);
  
  const myFunction = () => {
    if (typeof window !== 'undefined') {
      deviceWidth = window.innerWidth;
    }
    test = deviceWidth < 430 ? 320 : 375;
    console.log("text1", test);
      setTrueHeight(passedExhibitions.length * test);
      setTrueHeight(passedExhibitions.length * test);
      console.log("first one");
      console.log(trueHeight);
      console.log(deviceWidth);
      console.log("length", passedExhibitions.length)
      console.log("text2", test);
      setLeft(false);
      setRight(true);
  }
  
  const myFunction2 = () => {
    if (typeof window !== 'undefined') {
      deviceWidth = window.innerWidth;
    }
    test = deviceWidth < 430 ? 320 : 375;
    console.log("text1", test);
    setTrueHeight(actualExhibitions.length * test)
    setTrueHeight(actualExhibitions.length * test)
    console.log("first two");
    console.log(trueHeight);
    console.log(deviceWidth);
    console.log("length", actualExhibitions.length)

    console.log("text2", test);
    setLeft(true);
    setRight(false);

  }

  return (
    <div id='expo' className={Wrapper}>
      <h1 className={GrayTitle} style={{marginTop:"60px", marginBottom:"50px"}}>EXHIBITIONS</h1>
      <CoolTabs
          tabKey={'1'}
          style={{width:  "80vw", background: 'white', height: '100%'}}
          // style={{width:  "80vw", background: 'white'}}
          activeTabStyle={{ background:  'white', color:  'black' }}
          unActiveTabStyle={{ background:  'white', color:  'black'}}
          activeLeftTabBorderBottomStyle={{ background:  'rgb(27, 27, 27)', height:  1}}
          activeRightTabBorderBottomStyle={{ background:  'rgb(27, 27, 27)', height:  1 }}
          tabsBorderBottomStyle={{ background:  'white', height:  2 }}
          leftContentStyle={{ background:  'white'}}
          rightContentStyle={{ background:  'white'}}
          leftTabTitle={<p onClick={myFunction2} style={{textAlign: "center", padding: "10px"}}>Current exhbitions</p>}
          rightTabTitle={<p onClick={myFunction} style={{textAlign: "center", padding: "10px"}}>Past exhibitions</p>}
          // leftTabTitle={'Expositions du moment'}
          // rightTabTitle={'Expositions passées'}
          
          leftContent={<div className={ListWrapper}>
            {actualExhibitions.map((exhibition, i = 0) => (
              <EventCardExpo
                date={exhibition.date}
                title={exhibition.title}
                link={"/en/expositions/" + exhibition.link}
                place={exhibition.place}
                description={exhibition.description}
                description2={exhibition.description2}
                photo={exhibition.presentation_photo}
                artist={exhibition.artist}
                index={i}
                lenght={actualExhibitions.length}
              />
            ))}
          </div>}
          rightContent={<div className={ListWrapper}>
          {passedExhibitions.map((exhibition, i = 0) => (
            <EventCardExpo
              date={exhibition.date}
              title={exhibition.title}
              link={"/en/expositions/" + exhibition.link}
              place={exhibition.place}
              description={exhibition.description}
              photo={exhibition.presentation_photo}
              artist={exhibition.artist}
              index={i}
              lenght={passedExhibitions.length}
            />
          ))}
        </div>}
          // contentTransitionStyle={'transform 0.6s ease-in'}
          // borderTransitionStyle={'all 0.6s ease-in'}
          contentTransitionStyle={'0.8s'}
          borderTransitionStyle={'0.8s'}
      />
      {left && <div className={ListWrapper} style={{paddingTop: "10px"}}>
            {actualExhibitions.map((exhibition, i = 0) => (
              <EventCardExpo
                date={exhibition.date}
                title={exhibition.title}
                link={"/en/expositions/" + exhibition.link}
                place={exhibition.place}
                description={exhibition.description}
                description2={exhibition.description2}
                photo={exhibition.presentation_photo}
                artist={exhibition.artist}
                index={i}
                lenght={actualExhibitions.length}
              />
            ))}
      </div>}
      {right && <div className={ListWrapper} style={{paddingTop: "10px"}}>
          {passedExhibitions.map((exhibition, i = 0) => (
            <EventCardExpo
              date={exhibition.date}
              title={exhibition.title}
              link={"/en/expositions/" + exhibition.link}
              place={exhibition.place}
              description={exhibition.description}
              photo={exhibition.presentation_photo}
              artist={exhibition.artist}
              index={i}
              lenght={passedExhibitions.length}
            />
          ))}
        </div>}
      <div className={ButtonWrapper}>
        <Button
          alt=""
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location.href = "/en/contactez-nous";
            }
          }}
        >
          Contact-us
        </Button>
      </div>
    </div>
  );
};

export default Expositions;
